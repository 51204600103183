<template>
  <div>
    <div class="search-div m-b1">
      <a-form layout="inline">
        <a-form-item label="">
          <a-range-picker format="YYYY-MM-DD" :placeholder="['提交开始时间', '提交结束时间']" v-model:value="chooseTime"
            @change="timeChange" />
        </a-form-item>
        <a-form-item label="">
          <a-select style="width:140px" v-model:value="searchForm.reportType" placeholder="上报类型">
            <a-select-option :value="2">异常上报</a-select-option>
            <a-select-option :value="1">费用上报</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-select style="width:140px" v-model:value="searchForm.auditStatus" placeholder="审核状态">
            <a-select-option :value="0">待审核</a-select-option>
            <a-select-option :value="1">已通过</a-select-option>
            <a-select-option :value="2">未通过</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.carNo" placeholder="车牌号" />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.explainer" placeholder="上报人" />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.salesman" placeholder="业务员" />
        </a-form-item>
        <a-form-item label="">
          <div class="operate-btn">
            <a-button class="header-search-btn" type="primary" @click="searchData" :loading="loading">搜索</a-button>
            <a-button class="header-reset-btn" @click="resetData" :loading="loading">重置</a-button>
          </div>
        </a-form-item>
      </a-form>
    </div>
    <a-table :columns="columns" :row-key="record => record.orderId" :data-source="dataList" bordered
      :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #image="{ record }">
        <div>
          <a-image  v-show="record.fileRealUrl" :width="20" :height="20" :src="record.fileRealUrl" />
        </div>
      </template>
      <template #exceptionExplain="{ record }">
        <div class="remark-content">
          <span v-show="record.exceptionExplain?.split('').length < 39">{{ record.exceptionExplain }}</span>
          <a-tooltip color="#fff">
            <template #title>
              <span style="color:#000">{{ record.exceptionExplain }}</span>
            </template>
            <span v-show="record.exceptionExplain?.split('').length > 39">{{ record.exceptionExplain +
              record.exceptionExplain?.split(',').length
            }}</span>
          </a-tooltip>
        </div>
      </template>
      <template #auditStatus="{ record }">
        <div>
          <span class="title-color" :style="{ color: record.auditStatus?.value === 1 ? '#0C8226' : '#D9001B' }">{{
            record.auditStatus?.label }}</span>
          <a-tooltip>
            <template #title>{{ record.auditRemark || '没有审核备注' }}</template>
            <MessageOutlined
              :style="{ color: record.auditStatus?.value === 1 ? '#0C8226' : '#D9001B', marginLeft: '5px' }" />
          </a-tooltip>
        </div>
      </template>
      <template #operate="{ record }">
          <div v-show="record.auditStatus?.value === 0">
            <a @click="checkForm.id = record.id, checkShow = true">审核</a>
          </div>
          <div v-show="record.auditStatus?.value === 1 && record.reportType?.value === 1">
            <a @click="checkForm.id = record.id, cancelShow = true">取消</a>
          </div>
      </template>
    </a-table>
  </div>
  <a-modal v-model:visible="checkShow" title="审核" @ok="confirmCheck" @cancel="checkForm = JSON.parse(mirrorCheckForm)">
    <a-form :model="checkForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
      <a-form-item label="审核结果">
        <a-select v-model:value="checkForm.auditStatus" placeholder="同意或拒绝">
          <a-select-option :value="1">同意</a-select-option>
          <a-select-option :value="2">拒绝</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="备注">
        <a-textarea v-model:value="checkForm.auditRemark" placeholder="填写备注信息" :rows="4" />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="cancelShow" title="取消" @ok="confirmCancel" @cancel="checkForm = JSON.parse(mirrorCheckForm)">
    <a-form :model="checkForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
      <a-form-item label="取消原因">
        <a-textarea v-model:value="checkForm.auditRemark" placeholder="填写取消原因" :rows="4" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { abnomalList, checkOperate, cancelFeeException } from '@/api/transport/transport'
import { MessageOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'

export default {
  components: {
    MessageOutlined
  },
  setup () {
    const state = reactive({
      loading: false,
      checkShow: false,
      cancelShow: false,
      searchForm: {
        explainStartTime: null,
        explainEndTime: null,
        auditStatus: null,
        reportType: null,
        explainer: '',
        carNo: '',
        salesman: ''
      },
      mirrorSearchForm: {},
      checkForm: {
        id: '',
        carNo: '',
        auditRemark: null,
        auditStatus: null
      },
      mirrorCheckForm: {},
      chooseTime: [],
      dataList: [],
      columns: [
        {
          title: '上报类型',
          dataIndex: 'reportType.label'
        },
        {
          title: '上报车辆',
          dataIndex: 'carNo'
        },
        {
          title: '上报人',
          dataIndex: 'explainer'
        },
        {
          title: '上报时间',
          dataIndex: 'explainTime'
        },
        {
          title: '业务员',
          dataIndex: 'salesman'
        },
        {
          title: '异常类型',
          dataIndex: 'exceptionType.label'
        },
        {
          title: '费用金额',
          dataIndex: 'amount'
        },
        {
          title: '异常原因',
          dataIndex: 'exceptionSubType.label'
        },
        {
          title: '异常照片',
          dataIndex: 'image',
          slots: {
            customRender: 'image'
          }
        },
        {
          title: '所属任务段',
          dataIndex: 'transportType.label'
        },
        {
          title: '备注',
          width: '10%',
          dataIndex: 'exceptionExplain',
          slots: {
            customRender: 'exceptionExplain'
          }
        },
        {
          title: '审核结果',
          dataIndex: 'auditStatus.label',
          slots: {
            customRender: 'auditStatus'
          }
        },
        {
          title: '审核人',
          dataIndex: 'follower'
        },
        {
          title: '审核时间',
          dataIndex: 'followTime'
        },
        {
          title: '操作',
          dataIndex: 'operate',
          slots: {
            customRender: 'operate'
          }
        },
      ],
      pagination: {
        current: 1,
        size: 10,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
    })
    const loadData = () => {
      state.loading = true
      state.dataList = []
      abnomalList({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.dataList = res.data.records
          state.pagination.total = res.data.total
        }
      }).finally(() => { state.loading = false })
         
    }
    onMounted(() => {
      loadData()
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
      state.mirrorCheckForm = JSON.stringify(state.checkForm)
    })
    const searchData = () => {
      state.dataList = []
      state.pagination.current = 1
      loadData()
    }
    const resetData = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      state.chooseTime = []
      searchData()
    }
    const handleTableChange = (page) => {
      state.pagination = page
      loadData()
    }
    const timeChange = (e, v) => {
      state.searchForm.explainStartTime = e[0]
      state.searchForm.explainEndTime = e[1]
    }
    const confirmCheck = () => {
      if (state.checkForm.auditStatus) {
        if (state.checkForm.auditStatus !== 2) requestAPI()
        else {
          if (state.checkForm.auditStatus === 2 && state.checkForm.auditRemark) {
            requestAPI()
          } else message.error('请填写拒绝原因')
        }
      } else message.error('请选择同意或拒绝')
      function requestAPI () {
        checkOperate(state.checkForm).then(res => {
          if (res.code === 10000) {
            message.success('审核结果已提交')
            state.checkShow = false
            searchData()
          }
        })
      }
    }
    const confirmCancel = () => {
      if (state.checkForm.auditRemark) {
            cancelAPI()
          } else message.error('请填写取消原因')
      function cancelAPI () {
        cancelFeeException(state.checkForm).then(res => {
          if (res.code === 10000) {
            message.success('取消成功')
            state.checkShow = false
            searchData()
          }
        })
      }
    }
    return {
      ...toRefs(state),
      timeChange,
      searchData,
      resetData,
      handleTableChange,
      confirmCheck,
      confirmCancel
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
